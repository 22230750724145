
.brackets_class > div {
    /* overflow-y: hidden; */
    /* overflow: unset !important;
    overflow-x: auto !important; */

}

.brackets_class div div div {
    /* background-color: bisque; */
    overflow: inherit !important;
}

@media screen and (max-width: 992px) {
    .brackets_class > div {
        /* min-height: 1vh !important; */
        /* padding-bottom: 250px; */
    }
}