@font-face {
  font-family: Gotham;
  src: url(./fonts/Gotham-Book.otf);
  format: ("opentype");
}

/* line background color*/
.ant-table-thead th {
  text-align: center !important;
  background: #211931 !important;
  /* border: solid 1px red !important */
}

.ant-table-tbody > tr > td {
  border-bottom: solid 1px #211931 !important;
}
.ant-table-tbody > tr > td:last-child {
  border-right: solid 1px #211931 !important;
}
.ant-table-tbody > tr > td:first-child {
  border-left: solid 1px #211931 !important;
}
*/ .ant-table-tbody > tr:first-child > td {
  border-top: solid 1px #211931 !important;
}

.ant-table-tbody > tr > td {
  text-align: center !important;
  background-color: #211931 !important;
}
.ant-table-tbody > tr:nth-child(odd) > td {
  text-align: center !important;
  background: #211931 !important;
}

/*hover background odd*/
.ant-table-tbody > tr:hover > td {
  background: #211931 !important;
  border-top: solid 1px #211931 !important;
  border-bottom: solid 1px #211931 !important;
}
.ant-table-tbody > tr:hover > td:first-child {
  border-left: solid 1px #211931 !important;
}
.ant-table-tbody > tr:hover:nth-child(even) > td:last-child {
  border-right: solid 1px #211931 !important;
}
.ant-table-tbody > tr:hover:nth-child(even):last-child > td {
  border-bottom: solid 1px #211931 !important;
}
/*hover background even  adc3ed */
.ant-table-tbody > tr:hover:nth-child(odd) > td {
  background: #211931 !important;
  border-top: solid 1px #211931 !important;
  border-bottom: solid 1px #211931 !important;
}
.ant-table-tbody > tr:hover:nth-child(odd) > td:first-child {
  border-left: solid 1px #211931 !important;
}
.ant-table-tbody > tr:hover:nth-child(odd) > td:last-child {
  border-right: solid 1px #211931 !important;
}

/*pagination*/
.ant-table-pagination.ant-pagination {
  margin: 60px 0 !important;
}
.ant-pagination-item {
  background-color: #211931 !important;
  border-radius: 0 !important;
  border: 1px solid #211931 !important;
  margin-left: 10px !important;
}
.ant-pagination-item-link:after,
.ant-pagination-jump-prev:after,
.ant-pagination-jump-next:after {
  background-color: #211931 !important;
  border-radius: 0 !important;
  border: 1px solid #211931 !important;
}
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-left: 10px !important;
}

.ant-pagination-jump-prev:after,
.ant-pagination-jump-next:after {
  color: #999 !important;
}
.ant-pagination-item-link:first-child {
  /* margin-right:10px !important; */
}
.ant-pagination-item a,
.ant-pagination-item-link:after {
  color: #999 !important;
}
.ant-pagination-item-active {
  background-color: #f34278 !important;
  border-radius: 0 !important;
  border: 1px solid #f34278 !important;
}
.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-tooltip-placement-right {
  padding-left: 10px !important;
}

/*layout style*/
/*submenu*/
.ant-menu-dark {
  background-color: #212943 !important;
}
.ant-menu-inline.ant-menu-sub {
  background: #161633 !important;
}
/*menu title*/
.ant-menu-inline {
  background-color: #212943 !important;
}
.ant-menu-item-selected {
  background-color: #212943 !important;
}
/*menu out */
.ant-layout-sider-children {
  background-color: #212943 !important;
  min-height: 87vh !important;
}
.ant-layout-sider-children ul li {
  color: #adb6d5;
  font-size: 14px;
}
/*end*/
.ant-layout-sider-trigger {
  background: #212943 !important;
}
/* .ant-menu-inline-collapsed {
background-color:#dfcdeb !important
} */
.ant-menu-inline-collapsed {
  /* width: 65px  !important; */
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 60px !important;
  height: 60px !important;
}

.web3modal-modal-lightbox {
  z-index: 1500 !important;
}

.brackets-class {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.brackets-class-2 {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
}

.brackets-class-3 {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
}

.ant-skeleton-header {
  vertical-align: middle !important;
}

.slick-disabled {
  display: none !important;
}

.ant-popover-inner-content {
  padding: 0px 17px !important;
}

.toast-custom-body {
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.toast-custom-class {
  @media (max-width: 768px) {
    width: 300px !important;
    margin-right: 0 !important;
    text-align: left !important;
  }
}

.banner-loader {
  height: 274px !important;

  @media (max-width: 1024px) {
    height: 191px !important;
  }
  @media (max-width: 320px) {
    height: 65px !important;
  }
  @media (max-width: 768px) {
    height: 169px !important;
  }
  @media (max-width: 425px) {
    height: 90px !important;
  }
  @media (max-width: 375px) {
    height: 78px !important;
  }
}

.custom-scroll::-webkit-scrollbar {
  width: 6px !important;
  padding-left: 4px !important;
}

.custom-scroll::-webkit-scrollbar-track {
  background: #3f338ce6 !important;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #7265c8 !important;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #7265c8e6 !important;
}