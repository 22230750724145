.input_class {
  color: #fff !important;
  border: transparent !important;
  background: #3f2faa !important;
}

.btn_class {
  background: #4e3cca !important;
  border: transparent !important;
}

.btn_class .selected-flag:hover,
.btn_class .selected-flag:focus, 
.btn_class .selected-flag.open {
  background: #4e3cca !important;
}

.btn_class .arrow {
  border-top: 4px solid #a498ed !important;
}
.btn_class .arrow.up {
  display: none;
}

.dropdown_class {
  background-color: #3f2faa !important;
  color: #fff !important;
}
.dropdown_class .dial-code {
  display: none;
}

.dropdown_class .country.highlight {
  background-color: #4e3cca !important;
}
.dropdown_class .country:hover {
  background-color: #4e3cca !important;
}
.search_class {
  background-color: #3f2faa !important;
  padding-left: 2px !important;
}

.search_class input {
  width: 250px;
  background-color: #4e3cca;
  border: transparent !important;
}

.search_class input::placeholder {
  color: #ffffff80 !important;
}
